<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <b-form-group id="input-group-2" label="ปีที่" label-for="input-2">
                                <b-form-select v-model="yearSelected" :options="years"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ฉบับที่"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="issue" placeholder="กรอกฉบับที่"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="เลขหน้า"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="pageNumber" placeholder="กรอกเลขหน้า"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> 
                            <b-form-group id="input-group-2" label="วันที่เผยแพร่" label-for="input-2">
                                <b-form-input :id="`type-date`" :type="`date`" v-model="publishDate"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(saveJournal())>บันทึก</b-button>
                    </div>
                </b-form>

            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import institutionService from "../../../../../services/institution.service";
import journalService from "../../../../../services/journal.service";
import moment from "moment-timezone";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            journalId: null,
            issue: null,
            pageNumber: null,
            publishDate: null,
            years: [],
            yearSelected: null,
            timeZone: "Asia/Bangkok",
            dateNow: new Date(),
            currentTime: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
            {
                    text: "วารสาร",
                    href: "#",
                },
                {
                    text: "ตารางเล่มวารสาร",
                    to: { name: "journals" },
                },
                {
                    text: "แก้ไขเล่มวารสาร",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getYears() {
            for (let num = 1; num <= 100; num++) {
                this.years.push(num);
            }
        },
        getDateTime() {
            this.currentTime = "00:00:00";
        },
        getJournal: async function () {
            this.journalId = this.$route.params.id;
            let response = await journalService.getJournalById(this.journalId);
            let data = response.data.journals;
            this.yearSelected = data.year;
            this.issue = data.issue;
            this.pageNumber = data.page_number;
            this.publishDate= moment(data.publish_date).tz(this.timeZone).format('yyyy-MM-DD') ;
        },

        saveJournal: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.journalId,
                        year: this.yearSelected,
                        issue : this.issue,
                        page_number  : this.pageNumber,
                        publish_date : this.publishDate
                    }
                    await journalService.updateJournal(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; }, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }
            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }
        },
        isValidate() {
            this.errors = [];
            if (!this.issue) this.errors.push("Issue required.");
            if (!this.pageNumber) this.errors.push("Number of page required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.getDateTime();
        this.getYears();
        this.getJournal();
    }

};
</script>